const params = {
  APIPROTOCOL: process.env.APIPROTOCOL || 'http',
  APIHOST: process.env.APIHOST || 'localhost',
  APIPORT: process.env.APIPORT || 3000,
  APIVERSION: process.env.APIVERSION || '',

  APIHOSTIMAGE: process.env.APIHOSTIMAGE || 'localhost',
  APIPORTIMAGE: process.env.APIPORTIMAGE || 4000,
  APIVERSIONIMAGE: process.env.APIVERSIONIMAGE || ''
}

params.APIURLONLY = `${params.APIPROTOCOL}://${params.APIHOST}:${params.APIPORT}`
params.APIURL = `${params.APIPROTOCOL}://${params.APIHOST}:${params.APIPORT}${params.APIVERSION}`
params.APIUPLOAD = `${params.APIPROTOCOL}://${params.APIHOSTIMAGE}:${params.APIPORTIMAGE}${params.APIVERSIONIMAGE}`

export default {
  ...params
}

export const production = process.env.NODE_ENV === 'production'
export const apiAuth = '/auth'
export const apiUser = '/user'
export const apiSearch = '/search'
export const apiEvent = '/event'
export const apiLike = '/like'
export const apiAsset = '/asset'
export const apiTicket = '/ticket'
export const apiOrganizer = '/organizer'
export const apiMember = '/member'
export const apiArticle = '/article'
export const apiCategory = '/category'
export const apiProduct = '/product'
export const apiSlider = '/home/slider'
export const apiHome = '/home'
export const apiBank = '/bank'
export const apiHomeIntro = '/home/intro'
export const apiTestimonial = '/testimonial'
export const apiImage = '/image/upload'
export const apiPresentation = '/presentation/upload'
export const apiDocument = '/doc/upload'
export const apiPdf = '/pdf/upload'
export const apiUtils = '/utils'
