import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Instagram from '@material-ui/icons/Instagram'

import { withRouter } from 'next/router'
import { login } from 'actions/auth/loginAction'
import Input from 'components/FormField/Input'
import FormContainer from 'components/Layout/FormContainer'
import color from 'theme/color'
import validate from './validate'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  logoImage: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '0 20%',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto'
  },
  containerItem: {
    display: 'flex',
    padding: '30px 0 0 0',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonSignUp: {
    color: color.secondaryText
  },
  inputText: {
    padding: '12px 14px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px'
  },
  linkText: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30,
    paddingLeft: 20,
    paddingRight: 20
  },
  link: {
    marginLeft: 5
  },
  marginLeft: {
    marginLeft: 'auto !important',
    marginTop: 0
  },
  marginRight: {
    marginRight: 'auto !important',
    marginTop: 0
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: 40
  },
  textField: {
    width: '100%',
    margin: '1em 0'
  },
  buttonLoginContainer: {
    width: 342,
    display: 'flex',
    flexDirection: 'column'
  },
  media: {
    height: '100%',
    width: '100%',
    paddingTop: '96.25%'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
})

const Forms = ({
  loading,
  submitting,
  handleSubmit,
  error,
  router,
  dispatch,
  meta,
  errorMessage
}) => {
  const classes = useStyles()
  const onSubmit = async (values) => {
    if (!error) {
      dispatch(login(values, router.query.path))
    }
  }

  return (
    <div className={classes.container}>
      {/* <div className={classes.buttonContainer} /> */}
      <img src="/static/app/Login-mobilemdpi.png" alt="logo.png" className={classes.logoImage} />
      <FormContainer
        errorMessage={errorMessage}
        successMessage={meta.message}
      >
        <Field
          addItemRight={(<PersonOutlineOutlinedIcon style={{ color: color.primaryColor }} />)}
          name="email"
          label="Email"
          placeholder="Username / Email"
          maxLength={60}
          component={Input}
        />

        <Field
          addItemRight={(<LockOutlinedIcon style={{ color: color.primaryColor }} />)}
          name="password"
          label="Password"
          placeholder="Password"
          maxLength={30}
          type="password"
          component={Input}
        />
        <div>
          <Button
            type="submit"
            disabled={loading || submitting}
            variant="contained"
            color="primary"
            className={classes.textField}
            onClick={handleSubmit(onSubmit)}
          >
            <Typography style={{ color: color.white }}>
              Log in
            </Typography>
          </Button>
        </div>
        <div className={classes.containerItem}>
          <a target="_blank" rel="noreferrer" style={{ color: color.primaryText, textDecoration: 'none' }} href="https://buatbaju.com">www.buatbaju.com</a>
        </div>
        <div className={classes.containerItem}>
          <Instagram style={{ color: color.primaryText }} />
          <a target="_blank" rel="noreferrer" style={{ color: color.primaryText, textDecoration: 'none' }} href="https://www.instagram.com/masterpiece_id">
            @masterpiece_id
          </a>
        </div>
      </FormContainer>
    </div>
  )
}

Forms.propTypes = {
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  router: PropTypes.object
}

const mapStateToProps = (state) => ({
  loading: state.loginStore.loading,
  errorMessage: state.loginStore.errorMessage,
  meta: state.loginStore.meta
})

export default reduxForm({
  form: 'LoginEmail',
  validate
})(connect(mapStateToProps)(withRouter(Forms)))
